import './index.scss'
import { init } from '../utils/sentry'
import saveUserSource from '../utils/saveUserSource'

init()

// This default export is required in a new `pages/_app.js` file.
export default function MyApp({ Component, pageProps, err }) {
  saveUserSource()
  // Workaround for https://github.com/vercel/next.js/issues/8592
  return <Component {...pageProps} err={err} />
}
