import Cookies from 'js-cookie'

// runs client-side for better performance
function saveUserSource() {
  const isClient = typeof window != 'undefined' && window.document
  if (!isClient) return

  let source = Cookies.get('came_from')
  if (!source) {
    source = document.referrer
    if (!source) source = 'direct'
    Cookies.set('came_from', source)
  }

  return source
}

export default saveUserSource